import React, { Component } from "react";
import videoJS from "video.js";

import { options } from "./constants";
import "video.js/dist/video-js.css";

import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";

class VideoPlayerDASH extends Component {
  videoPlayer;
  constructor(props) {
    super(props);
    
    this.state = {
      sourceUrl:
        "https://slemnah.mfs.video.llnw.net/smedia/794a94bcffd94514897c0407717a6143/Cj/YLSUvMS6R8RxMvoGA-EDkhb__As0OLtf1U2P_wtXA/dash/soundcity.ism/soundcity.mpd"
    };
  }

  componentDidMount() {
    this.setupVideoPlayer();
  }

  setupVideoPlayer = () => {
    this.videoPlayer = videoJS("sandbox-dash", options, () => {
      // console.log("HELLO");
    });
    this.initializePlugins();
  };

  initializePlugins = () => {
    this.videoPlayer.httpSourceSelector();
  };

  updateSourceUrl = (sourceUrl) => {
    this.setState({
      sourceUrl: sourceUrl
    });
  };

  getBlobURL = async (code, type) => {
    let response = await fetch(
      "https://slemnah.mfs.video.llnw.net/smedia/794a94bcffd94514897c0407717a6143/Cj/YLSUvMS6R8RxMvoGA-EDkhb__As0OLtf1U2P_wtXA/dash/soundcity.ism/soundcity.mpd"
    );
    let xml = await response.text();
    const blob = new Blob([xml], { type: "application/dash+xml" });
    const url = URL.createObjectURL(blob);
    return url;
  };

  renderSource = () => {
    return <source src={this.state.sourceUrl} type={"application/dash+xml"} />;
  };

  // changeUrl = () => {
  //   const blobUrl = this.getBlobURL(
  //     "https://slemnah.mfs.video.llnw.net/smedia/794a94bcffd94514897c0407717a6143/Cj/YLSUvMS6R8RxMvoGA-EDkhb__As0OLtf1U2P_wtXA/dash/soundcity.ism/soundcity.mpd",  "application/dash+xml" ).then((data) => {
  //     this.updateSourceUrl(data);
  //   });
  // };

  render() {
    return (

    
      <div>
        <p>Source: {this.state.sourceUrl}</p>
        {/* <button onClick={this.changeUrl}>Change URL</button> */}
        <video
          alt={`${"sandbox"} - video`}
          className={`${"video-js"} ${"vjs-default-skin"} ${"vjs-big-play-centered"}`}
          controls
          preload={"auto"}
          id={"sandbox-dash"}
          width={1920}
          height={1080}
          data-setup="{}"
          playsInline
          data-vjs-player
        >
          {this.renderSource()}
         
        </video>
      </div>
    );
  }
}

export default VideoPlayerDASH;



export const options = {
    fluid: true,
    responsive: true,
    playbackRates: [0.75, 1, 1.25, 1.5, 2],
    controlBar: {
      children: [
        "playToggle",
        "progressControl",
        "volumePanel",
        "currentTimeDisplay",
        "timeDivider",
        "durationDisplay",
        "liveDisplay",
        "playbackRateMenuButton",
        "chaptersButton",
        "fullscreenToggle"
      ]
    }
  };
import React, { Component } from "react";
import videoJS from "video.js";

import { options } from "./constants";
import "video.js/dist/video-js.css";

import "videojs-contrib-quality-levels";
import "videojs-http-source-selector";

class VideoPlayerHLS extends Component {


  

  videoPlayer;
  constructor(props) {
   
    super(props);
     
    this.state = {
      sourceUrl:
        "https://notflixlayer-ll.slzr.cloud/videos/hls/soundcity/playlist.m3u8"
    };
  }

  componentDidMount() {
    this.setupVideoPlayer();
  }

  setupVideoPlayer = () => {
    this.videoPlayer = videoJS("sandbox-hls", options, () => {
      // console.log("HELLO");
    });
    this.initializePlugins();
  };

  initializePlugins = () => {
    this.videoPlayer.httpSourceSelector();
  };

  updateSourceUrl = (sourceUrl) => {
    this.setState({
      sourceUrl: sourceUrl
    });
  };

  getBlobURL = async (code, type) => {
    let response = await fetch(
      "https://notflixlayer-ll.slzr.cloud/videos/hls/soundcity/playlist.m3u8"
    );
    let xml = await response.text();
    const blob = new Blob([xml], { type: "application/x-mpegURL" });
    const url = URL.createObjectURL(blob);
    return url;
  };

  renderSource = () => {
    return <source src={this.state.sourceUrl} type={"application/x-mpegURL"} />;
  };

  changeUrl = () => {
    const blobUrl = this.getBlobURL(
      "https://slemnah.content.video.llnw.net/smedia/794a94bcffd94514897c0407717a6143/Cj/YLSUvMS6R8RxMvoGA-EDkhb__As0OLtf1U2P_wtXA/soundcity-3af4f2252e0c601edf783d3301c2ee401eb0b488.m3u8",  "application/x-mpegURL" ).then((data) => {
      this.updateSourceUrl(data); 

      
    });
  };

  render() {
    return (
      <div>
        <p>Source: {this.state.sourceUrl}</p>
        <button onClick={this.changeUrl}>Change URL</button>
        <video
          alt={`${"sandbox"} - video`}
          className={`${"video-js"} ${"vjs-default-skin"} ${"vjs-big-play-centered"}`}
          controls
          preload={"auto"}
          id={"sandbox-hls"}
          width={1920}
          height={1080}
          data-setup="{}"
          playsInline
          data-vjs-player
        >
          {this.renderSource()}
        </video>
      </div>
    );
  }
}

export default VideoPlayerHLS;


